import { createSlice } from '@reduxjs/toolkit'

export const step3OfflineSlice = createSlice({
  name: 'step3Offline',
  initialState: {
    value: {
      // firstName:"",
      // lastName:"",
      // email:"",
      // phoneNumber:"",
      // country:"",
      // shippingAddress:"",
      // firstName:"",
      // firstName:"",
      // firstName:"",
      // firstName:"",
      // firstName:"",
      // firstName:"",
    },
  },
  reducers: {
    setData: (state,action) => {
      // const mergedState = { ...this.initialState}
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // mergedState['firstName'] = action.payload.firstName
      // return{
      //   ...state = action.payload
      // }
      state.value = action.payload 
    },
  },
})

// Action creators are generated for each case reducer function
export const { setData } = step3OfflineSlice.actions

export default step3OfflineSlice.reducer
