import { createSlice } from '@reduxjs/toolkit'

export const step4VirtualSlice = createSlice({
  name: 'step4Virtual',
  initialState: {
  },
  reducers: {
    setData: (state,action) => {      
      // const mergedState = { ...initialState}
      // var init = {firstname:"",lastname:""}
      //   init = {...init,firstname:"abc"}
      //   alert(JSON.stringify(init))
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // mergedState['firstName'] = action.payload.firstName
      // return{
      //   ...state = action.payload
      // }
      state.value = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setData } = step4VirtualSlice.actions

export default step4VirtualSlice.reducer
