import { lazy, Suspense, useEffect, useState,useContext } from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

import { stack as Menu } from 'react-burger-menu'
import { LanguageContext } from '../Context/Context';

// API
import axios from 'axios'

// Components
import Loader from '../Components/Loading'

// Asset
import LogoNavbar from '../Assets/Images/LogoNavbar.png'
import Logo from '../Assets/Images/Logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faGlobe, faTimes } from '@fortawesome/free-solid-svg-icons'

import $ from 'jquery'
const NavbarLink = (props) =>{
    return (
    <Link to={props.to}
    >
        <div className={`font-semibold text-lg px-2 ${props.active ? 'text-orange-400' : ''}`}>
            {props.text}
        </div>
    </Link>
    )
}
const DrowpDownItem = (props)=>{
    return (
        <Link acti to={props.to}>
        <div className='border-b-2 px-2 py-2'>
            {props.text}
        </div>
        </Link>
        
    )
}
const DropDown = (props) =>{
    const [open,setOpen] = useState(false)
    
    return (
        <div onMouseLeave={()=>{setOpen(false)}}>
            <div className={`font-semibold text-lg px-2 cursor-pointer ${props.active? 'text-orange-500' : ''}`} onClick={()=>{setOpen(!open)}}>
                {props.text}
            </div>
            {open ? 
            <div className='absolute bg-[#FF7A1A] text-white font-semibold ml-2 shadow-lg' >
                <DrowpDownItem text={'Greeting'} to={'/greeting'}></DrowpDownItem>
                <DrowpDownItem text={'Important Information'} to={'/information'}></DrowpDownItem>
                <DrowpDownItem text={'FAQ & Rules'} to={'/faq'}></DrowpDownItem>
                <DrowpDownItem text={'Participant List'} to={'/participant'}></DrowpDownItem>            
            </div> 
            : <></>
            }
            
        </div>
    
    )
}
const BasicLayout = () =>{
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [drawer,setDrawer]= useState(false)
    const [drawerMobile,setDrawerMobile]= useState(false)

    const {getLanguage,changeLanguage} = useContext(LanguageContext)

    useEffect(()=>{
        // $('#react-burger-menu-btn').hide()
    },[])
    return (
    <div id="outer-container">
        {/* HEADER */}
        <Menu pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } customBurgerIcon={false} isOpen={ drawer }  right className='bg-white' width={240} onClose={()=>setDrawer(false)} styles={{zIndex:6}}>                      
                <div className='pl-10'>
                    <div className='font-semibold mt-5'>
                        <Link to={'/'}>Home</Link>
                    </div>
                    <div className='font-semibold mt-5'>
                        <Link to={'/virtualrun'}>Virtual Run</Link>
                    </div>
                    <div className={`font-semibold  mt-5`} onMouseLeave={()=>{setDrawerMobile(false)}}>
                        <div onClick={()=>{setDrawerMobile(!drawerMobile)}}>
                            Information
                        </div>
                        {
                            drawerMobile == true ? 
                            <div className='ml-3 font-thin'>
                                <div className='my-1'>
                                    <Link to={'/greeting'}>Greeting</Link>
                                </div>
                                <div className='mb-1'>
                                    <Link to={'/information'}>Important Information</Link>
                                </div>
                                <div className='mb-1'>
                                    <Link to={'/faq'}>FAQ &Rules</Link>                                    
                                </div>
                                <div className='mb-1'>
                                    <Link to={'/participant'}>Participant List</Link>                                    
                                </div>
                            </div>:<></>
                        }
                        
                    </div>
                    <div className='font-semibold mt-5'>
                        <Link to={'/gallery'}>Gallery</Link>
                    </div>
                    <div className='font-semibold mt-5'>
                        <Link to={'/contactus'}>Contact Us</Link>
                    </div>
                    <div className='font-semibold mt-5 rounded border px-2 w-fit'>
                        <FontAwesomeIcon icon={faGlobe} size="sm"></FontAwesomeIcon>
                        <select value={getLanguage()} onChange={(e)=>{changeLanguage(e.target.value)}}>
                            <option value="ID">ID</option>
                            <option value="EN">EN</option>
                        </select>
                    </div>
                </div>
                
            </Menu> 
        
        <div style={{zIndex:5}} className='fixed z-2 top-0 w-screen h-[100px] px-[20px] md:px-[50px] lg:px-[80px] xl:px-[150px] 2xl:px-[300px] flex justify-between shadow-xl bg-white'>
            <div className='flex items-center'>
                <Link className='cursor-pointer' to={'/'}>
                    <img src={LogoNavbar} className="w-[200px] md:w-[300px]"></img>
                </Link>
            </div>
            <div className='hidden lg:flex items-center '>
                <NavbarLink active={splitLocation[1] == ''} to={'/'} text={'Home'}></NavbarLink>
                <NavbarLink active={splitLocation[1] == 'virtualrun'} to={'/virtualrun'} text={'Virtual Run'}></NavbarLink>
                <DropDown active={splitLocation[1] == 'greeting' || splitLocation[1] == 'information' || splitLocation[1] == 'faq' || splitLocation[1] == 'participant'} to={'/'} text={'Information'}></DropDown>
                <NavbarLink active={splitLocation[1] == 'gallery'} to={'/gallery'} text={'Gallery'}></NavbarLink>
                <NavbarLink active={splitLocation[1] == 'contactus'} to={'/contactus'} text={'Contact Us'}></NavbarLink>
                <div className='font-semibold flex justify-center items-center rounded border px-2'>
                    <FontAwesomeIcon icon={faGlobe} size="sm"></FontAwesomeIcon>
                    <select value={getLanguage()} onChange={(e)=>{changeLanguage(e.target.value)}}>
                        <option value="ID">ID</option>
                        <option value="EN">EN</option>
                    </select>
                </div>
            </div>
            <div className='flex lg:hidden items-center'>
                <FontAwesomeIcon className='text-xl border px-1 py-1 rounded' onClick={()=>{setDrawer(!drawer)}} icon={drawer ? faTimes :faBars}>                
                </FontAwesomeIcon>                
                
            </div>
        </div>
            
        <div id="page-wrap">
            {/* CONTENT */}
            <div className='min-h-[80vh] mt-[100px] px-[20px] md:px-[50px] lg:px-[80px] xl:px-[150px] 2xl:px-[300px]'>
                <Suspense fallback={<Loader text="Loading page ..." />}>
                    <Outlet />
                </Suspense>
            </div>
            {/* FOOTER */}
            <div className='mt-10 px-[20px] md:px-[50px] lg:px-[80px] xl:px-[150px] 2xl:px-[300px] bg-gray-200'>
                <div className='flex justify-between py-10'>
                    <div>
                        <img src={Logo} className="w-[200px] md:w-[300px]"></img>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className='bg-gray-200'>
                <div className='border-t-2 border-gray-300'>
                    <div className='px-[20px] md:px-[50px] lg:px-[80px] xl:px-[150px] 2xl:px-[300px] text-sm py-1'>
                        2023 &#169;	Copyright. All rights Reserved.
                    </div>
                </div>
            </div>
        </div>  
        
        
    </div>
    )
}
export default BasicLayout