import React from 'react'
import Router from './routes'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import LanguageContextWrapper from './Context/LanguageContextWrapper';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
      <BrowserRouter basename='/'>
        <LanguageContextWrapper>
            <ToastContainer 
                autoClose={1000} 
                closeButton={false}
                theme='colored'
                className='h-5'
            />
          <Router />
        </LanguageContextWrapper>
      </BrowserRouter>
  )
}

export default App

