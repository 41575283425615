import { combineReducers, configureStore } from '@reduxjs/toolkit'
import step1VirtualSlice from './slice/step1VirtualSlice'
import step2VirtualSlice from './slice/step2VirtualSlice'
import step3VirtualSlice from './slice/step3VirtualSlice'
import step4VirtualSlice from './slice/step4VirtualSlice'

import step1OfflineSlice from './slice/step1OfflineSlice';
import step2OfflineSlice from './slice/step2OfflineSlice';
import step3OfflineSlice from './slice/step3OfflineSlice';
import step4OfflineSlice from './slice/step4OfflineSlice';


import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore ,FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfigVirtual1 = {
    key: 'virtual1',
    storage,
}
const persistConfigVirtual2 = {
    key: 'virtual2',
    storage,
}
const persistConfigVirtual3 = {
    key: 'virtual3',
    storage,
}
const persistConfigVirtual4 = {
    key: 'virtual4',
    storage,
}

const persistConfigOffline1 = {
    key: 'offline1',
    storage,
}
const persistConfigOffline2 = {
    key: 'offline2',
    storage,
}
const persistConfigOffline3 = {
    key: 'offline3',
    storage,
}
const persistConfigOffline4 = {
    key: 'offline4',
    storage,
}
  
const step1VirtualReducer = persistReducer(persistConfigVirtual1, step1VirtualSlice)
const step2VirtualReducer = persistReducer(persistConfigVirtual2, step2VirtualSlice)
const step3VirtualReducer = persistReducer(persistConfigVirtual3, step3VirtualSlice)
const step4VirtualReducer = persistReducer(persistConfigVirtual4, step4VirtualSlice)

const step1OfflineReducer = persistReducer(persistConfigOffline1, step1OfflineSlice)
const step2OfflineReducer = persistReducer(persistConfigOffline2, step2OfflineSlice)
const step3OfflineReducer = persistReducer(persistConfigOffline3, step3OfflineSlice)
const step4OfflineReducer = persistReducer(persistConfigOffline4, step4OfflineSlice)

export const store = configureStore({
  reducer: {
    step1Offline:step1OfflineReducer,
    step2Offline:step2OfflineReducer,
    step3Offline:step3OfflineReducer,
    step4Offline:step4OfflineReducer,
    step1Virtual:step1VirtualReducer,
    step2Virtual:step2VirtualReducer,
    step3Virtual:step3VirtualReducer,
    step4Virtual:step4VirtualReducer,

  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
})

export const persistor = persistStore(store)

