import React, { useState, useEffect } from 'react';
import { LanguageContext } from './Context';

export default function LanguageContextWrapper(props) {

    const [language, setLanguage] = useState(localStorage.getItem("lang"));
  
    useEffect(() => { 
        if(language != "ID" && language != "EN") {setLanguage("ID");localStorage.setItem("lang","ID")}
        // console.log(language)
    }, [])

    function changeLanguage(lang) {
        setLanguage(lang);
        localStorage.setItem("lang",lang)
        // console.log("a", lang)
    }
    function getLanguage(){
        // console.log("b", language)
        return language
    }
    return (
        <LanguageContext.Provider value={{ getLanguage: getLanguage, changeLanguage: changeLanguage }}>
          {props.children}
        </LanguageContext.Provider>
      );
}